import { supportEmail } from '@mm/common'
import { Flex, Text, ExternalLink } from '@mm/company-ui'
import React from 'react'

export const FrontFooter = () => {
  return (
    <footer sx={{ width: '100%' }}>
      <Flex
        row
        sx={{
          width: '100%',
          borderTop: '1px solid',
          borderTopColor: 'background-light',
        }}
      >
        <Flex justify="space-between" align="center" sx={{ marginY: 2, width: '100%' }}>
          <Text variant="body" as="span" sx={{ color: 'text-light', fontSize: [11, 13] }}>
            ©2022 companyOS
          </Text>
          <Flex>
            <ExternalLink href="/terms" variant="secondary" sx={{ fontSize: [11, 13] }}>
              Terms of Service
            </ExternalLink>
            <ExternalLink href="/privacy" variant="secondary" sx={{ marginLeft: [1, 2], fontSize: [11, 13] }}>
              Privacy Policy
            </ExternalLink>
            <ExternalLink
              href={`mailto:${supportEmail}`}
              variant="secondary"
              sx={{ marginLeft: [1, 2], fontSize: [11, 13] }}
            >
              Contact
            </ExternalLink>
          </Flex>
        </Flex>
      </Flex>
    </footer>
  )
}
