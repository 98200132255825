import { Flex } from '@mm/company-ui'
import React from 'react'
import { FrontFooter } from './FrontFooter'
import { FrontHeader, FrontHeaderProps } from './FrontHeader'

export type FrontFrameProps = {
  children: React.ReactNode
}

export const FrontFrame = ({ children, title, logoOnly }: FrontFrameProps & FrontHeaderProps) => {
  return (
    <Flex column sx={{ height: '100%', width: '100%', maxWidth: '1600px', margin: 'auto' }}>
      <FrontHeader title={title} logoOnly={logoOnly} />
      <Flex grow column justify="space-between" sx={{ marginX: [2, 8] }}>
        <div sx={{ height: '100%', paddingY: 2 }}>{children}</div>
        <FrontFooter />
      </Flex>
    </Flex>
  )
}
