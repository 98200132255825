import { Flex, Button } from '@mm/company-ui'
import { CompanyOSFullLogoIcon, ExternalLink } from '@mm/company-ui-icons'
import Link from 'next/link'
import React from 'react'
import { Head } from './Head'

export type FrontHeaderProps = {
  logoOnly?: boolean
  title?: string
}

export const FrontHeader = ({ title, logoOnly = false }: FrontHeaderProps) => {
  return (
    <>
      <Head title={title} />
      <header>
        <Flex row sx={{ width: '100%' }}>
          <Flex
            justify={logoOnly ? 'center' : 'space-between'}
            align="center"
            sx={{ marginY: 2, marginX: [2, 8], width: '100%' }}
          >
            <Link href="/">
              <CompanyOSFullLogoIcon width={3} height={3} color="text" sx={{ cursor: 'pointer' }} />
            </Link>
            {!logoOnly && (
              <Flex>
                <Button
                  size="small"
                  variant="muted"
                  endIcon={<ExternalLink />}
                  onClick={() => window.open('https://mocharymethod.com/#coaches', '_blank')}
                >
                  Our Coaches
                </Button>
                <Button
                  size="small"
                  variant="muted"
                  endIcon={<ExternalLink />}
                  onClick={() => window.open('/learn', '_blank')}
                  sx={{ marginLeft: [2, 4] }}
                >
                  Curriculum
                </Button>
                <Link href="/login">
                  <Button size="small" sx={{ marginLeft: [2, 4] }}>
                    Login
                  </Button>
                </Link>
              </Flex>
            )}
          </Flex>
        </Flex>
      </header>
    </>
  )
}
